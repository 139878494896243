import React from 'react';
import './SidebarMenu.css'; // CSS dosyasını import edin

const SidebarMenu = ({ onOpenSidebar, isSidebarOpen, selectedButton  }) => {
  return (
    <div className={`sidebar-menu ${isSidebarOpen ? 'open' : ''}`}>
      <button onClick={() => onOpenSidebar('bathroomStorageProducts', 'bathroomStorageProducts')}
        className={`menu-button ${selectedButton === 'bathroomStorageProducts' ? 'open' : ''}`} data-tooltip="Banyo depolama ürünleri">
       <svg viewBox="0 0 24 24" focusable="false" width="24" height="24" aria-hidden="true"  className={'position-relative'}><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2v2h12V2h2v18.5c0 .8284-.6716 1.5-1.5 1.5s-1.5-.6716-1.5-1.5c0-.6531.4174-1.2087 1-1.4146V17H6v3.5c0 .8284-.6716 1.5-1.5 1.5S3 21.3284 3 20.5c0-.6531.4174-1.2087 1-1.4146V2h2zm12 13v-3.5H6V15h12zm0-5.5V6H6v3.5h12z"></path></svg> {/* Düzenle simgesi */}
      </button>
      <button onClick={() => onOpenSidebar('bathroom', 'bathroom')}
        className={`menu-button ${selectedButton === 'bathroom' ? 'open' : ''}`} data-tooltip="Lavobo üniteleri" >
        <svg viewBox="0 0 24 24" focusable="false" width="24" height="24" aria-hidden="true" className={'position-relative'}><path fill-rule="evenodd" clip-rule="evenodd" d="M14 2c-1.6569 0-3 1.3432-3 3v3H2c0 5 2.9504 7.5599 6.5 8.738V22h7v-5.262C19.0496 15.5599 22 13 22 8h-9V5c0-.5523.4477-1 1-1s1 .4477 1 1h2c0-1.6568-1.3431-3-3-3zm-9.9223 8h15.8446c-.5166 3.183-3.662 4.457-6.4223 5.2457V20h-3v-4.7543C7.7397 14.457 4.5943 13.183 4.0777 10z"></path></svg>
      </button>
      <button onClick={() => onOpenSidebar('furniture', 'furniture')}
        className={`menu-button ${selectedButton === 'furniture' ? 'open' : ''}`} data-tooltip="Aynalar">
        <svg viewBox="0 0 24 24" focusable="false" width="24" height="24" aria-hidden="true"  className={'position-relative'}><path fill-rule="evenodd" clip-rule="evenodd" d="M9 8.5C9 4.9101 11.9101 2 15.5 2S22 4.9101 22 8.5V22H9V8.5zM15.5 4c-2.0119 0-3.7155 1.3204-4.2914 3.1419.3392-.0983.6938-.1419 1.0476-.1419 1.9805 0 3.2437 1.6431 3.2437 3.5325 0 1.8959-1.2774 3.4675-3.2437 3.4675-.4351 0-.8613-.0714-1.2562-.2218v1.7253c.931-.0323 1.867-.0035 2.7985-.0035.8253 0 1.5943.1708 2.2595.566l.0051.003c.6378.3839 1.1283.9209 1.46 1.5901.3638.7337.4768 1.5306.4768 2.3409H20V8.5C20 6.0147 17.9853 4 15.5 4zm.4999 16c0-.848-.1891-1.7468-.9658-2.216-.2902-.1717-.6864-.284-1.2356-.284-.9296 0-1.8701-.0448-2.7985.0053V20h4.9999zm-3.7437-8c.8671 0 1.2437-.6624 1.2437-1.4675 0-.7932-.3685-1.5325-1.2437-1.5325-.9228 0-1.2498.6443-1.2562 1.5027C11 11.2884 11.3891 12 12.2562 12z"></path><path d="M2 17.5h1.7986c.8061 0 1.5596.2947 1.9327 1.0474C6.0347 19.1592 6 19.8369 6 20.5h2c0-.9807-.0301-1.9399-.4768-2.8409-.3317-.6692-.8222-1.2061-1.46-1.5901l-.0051-.003c-.6652-.3952-1.4342-.566-2.2595-.566H2v2zm0-3.5084A3.897 3.897 0 0 0 2.2563 14C4.2226 14 5.5 12.4284 5.5 10.5325 5.5 8.6431 4.2368 7 2.2563 7c-.0855 0-.171.0025-.2563.0078V9.015A2.0824 2.0824 0 0 1 2.2563 9C3.1315 9 3.5 9.7393 3.5 10.5325 3.5 11.3376 3.1234 12 2.2563 12A1.794 1.794 0 0 1 2 11.9825v2.0091z"></path></svg>
      </button>
    </div>

  );
};

export default SidebarMenu;
