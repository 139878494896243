import React, { useRef, useEffect, useState, useCallback } from 'react';
import { TransformControls } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Door = ({ id, position, setControlsEnabled, wall, roomSize }) => {
  const doorRef = useRef();
  const controlsRef = useRef();
  const [currentWall, setCurrentWall] = useState(wall);
  const [doorSize] = useState({ width: 1, height: 1.5, depth: 0.1 });
  const { camera, gl } = useThree();

  // Update the rotation of the door based on the wall it is attached to
  const updateRotation = useCallback((wall) => {
    if (doorRef.current) {
      switch (wall) {
        case 'left':
          doorRef.current.rotation.set(0, Math.PI / 2, 0);
          break;
        case 'right':
          doorRef.current.rotation.set(0, -Math.PI / 2, 0);
          break;
        case 'front':
          doorRef.current.rotation.set(0, 0, 0);
          break;
        case 'back':
          doorRef.current.rotation.set(0, Math.PI, 0);
          break;
        default:
          break;
      }
    }
  }, []);

  // Initial position and rotation setup
  useEffect(() => {
    if (doorRef.current) {
      doorRef.current.position.set(position[0], doorSize.height / 2, position[2]);
      updateRotation(currentWall);
    }
  }, [position, currentWall, updateRotation]);

  // Set up transform controls
  useEffect(() => {
    if (controlsRef.current && doorRef.current) {
      controlsRef.current.attach(doorRef.current);
      controlsRef.current.setSpace('local');
      controlsRef.current.setMode('translate');
    }
  }, [doorRef.current]);

  // Detach controls when component is unmounted
  useEffect(() => {
    return () => {
      if (controlsRef.current) {
        controlsRef.current.detach();
      }
    };
  }, []);

  const handlePointerDown = () => setControlsEnabled(false);
  const handlePointerUp = () => setControlsEnabled(true);

  // Restrict movement to specified planes
  useFrame(() => {
    if (controlsRef.current && doorRef.current) {
      const { x, y, z } = doorRef.current.position;

      // Determine which wall the door is closest to
      let newWall = currentWall;

      if (Math.abs(x + roomSize.width / 2) < 0.2) {
        newWall = 'left';
      } else if (Math.abs(x - roomSize.width / 2) < 0.2) {
        newWall = 'right';
      } else if (Math.abs(z + roomSize.depth / 2) < 0.2) {
        newWall = 'front';
      } else if (Math.abs(z - roomSize.depth / 2) < 0.2) {
        newWall = 'back';
      }

      // Update wall and restrict movement accordingly
      if (newWall !== currentWall) {
        setCurrentWall(newWall);
        updateRotation(newWall);
      }

      const yOffset = doorSize.height / 2;

      // Restrict movement within the boundaries of the specified wall
      if (newWall === 'left' || newWall === 'right') {
        doorRef.current.position.set(
          newWall === 'left' ? -roomSize.width / 2 + doorSize.depth / 2 : roomSize.width / 2 - doorSize.depth / 2,
          yOffset,
          THREE.MathUtils.clamp(z, -roomSize.depth / 2 + doorSize.width / 2, roomSize.depth / 2 - doorSize.width / 2)
        );
      } else if (newWall === 'front' || newWall === 'back') {
        doorRef.current.position.set(
          THREE.MathUtils.clamp(x, -roomSize.width / 2 + doorSize.width / 2, roomSize.width / 2 - doorSize.width / 2),
          yOffset,
          newWall === 'front' ? -roomSize.depth / 2 + doorSize.depth / 2 : roomSize.depth / 2 - doorSize.depth / 2
        );
      }
    }
  });

  return (
    <>
      <TransformControls
        ref={controlsRef}
        args={[camera, gl.domElement]}
        onMouseDown={handlePointerDown}
        onMouseUp={handlePointerUp}
      />
      <mesh ref={doorRef}>
        <boxGeometry args={[doorSize.width, doorSize.height, doorSize.depth]} />
        <meshStandardMaterial color="red" />
      </mesh>
    </>
  );
};

export default Door;
