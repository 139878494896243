import React, { useState, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Room from './Room';
import SidebarMenu from './sidebar/SidebarMenu'; // Yan panel bileşenleri
import SidebarContent from './sidebar/SidebarContent';
import './App.css';

const CameraControl = ({ isEditing }) => {
  const { camera } = useThree();

  useEffect(() => {
    if (isEditing) {
      camera.position.set(0, 10, 0); // Üstten görünüm
      camera.lookAt(0, 0, 0);
    } else {
      camera.position.set(8, 1.5, 8); // 3D Görüntüleme açısı
      camera.lookAt(0, 1.25, 0);
    }
  }, [isEditing, camera]);

  return null;
};

const App = () => {
  const [roomSize, setRoomSize] = useState({
    width: 3,
    height: 2.5,
    depth: 3,
    leftWidth: 1,
    rightWidth: 1,
    leftDepth: 1,
    rightDepth: 1,
  });
  const [items, setItems] = useState([]);
  const [controlsEnabled, setControlsEnabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [selectedButton, setSelectedButton] = useState('');

  // Kullanıcı odanın boyutlarını değiştirmek istediğinde bu işlevi kullanabilirsiniz
  const handleRoomSizeChange = (dimension, value) => {
    setRoomSize((prevSize) => ({
      ...prevSize,
      [dimension]: value,
    }));
  };

  // Kamera açılarını değiştirmek için kullanılan fonksiyonlar
  const handleEditRoom = () => {
    setIsEditing(true);
  };

  const handleViewRoom = () => {
    setIsEditing(false);
  };

  // GLTF modelini eklemek için kullanılan fonksiyon
  const handleAddFurniture = () => {
    setItems((prevItems) => [
      ...prevItems,
      {
        id: prevItems.length + 1,
        type: 'furniture',
        wall: 'front',
        position: [0, 0, -1 + prevItems.length * 0.5],
      },
    ]);
  };

  // Kapı ekleme fonksiyonu
  const handleAddDoor = () => {
    setItems((prevItems) => [
      ...prevItems,
      { id: prevItems.length + 1, type: 'door', wall: 'front', position: [0, 0, -1] },
    ]);
  };

  return (
    <div className="App">
      <SidebarMenu
        onOpenSidebar={(menu, buttonId) => {
          console.log('Tıklanan Buton:', buttonId);
          setSelectedButton(buttonId);
          setSelectedMenu(menu);
          setIsSidebarOpen(true);
        }}
        isSidebarOpen={isSidebarOpen}
        selectedButton={selectedButton}
      />
      <SidebarContent
        visible={isSidebarOpen}
        selectedMenu={selectedMenu}
        onClose={() => setIsSidebarOpen(false)}
      />

      {/* Boyutları ayarlamak için input alanları */}
      <div style={{ margin: '10px' }}>
        <label>
          Genişlik:
          <input
            type="number"
            value={roomSize.width}
            onChange={(e) => handleRoomSizeChange('width', parseFloat(e.target.value))}
          />
        </label>
        <label>
          Yükseklik:
          <input
            type="number"
            value={roomSize.height}
            onChange={(e) => handleRoomSizeChange('height', parseFloat(e.target.value))}
          />
        </label>
        <label>
          Derinlik:
          <input
            type="number"
            value={roomSize.depth}
            onChange={(e) => handleRoomSizeChange('depth', parseFloat(e.target.value))}
          />
        </label>
        <label>
          Sol Genişlik:
          <input
            type="number"
            value={roomSize.leftWidth}
            onChange={(e) => handleRoomSizeChange('leftWidth', parseFloat(e.target.value))}
          />
        </label>
        <label>
          Sağ Genişlik:
          <input
            type="number"
            value={roomSize.rightWidth}
            onChange={(e) => handleRoomSizeChange('rightWidth', parseFloat(e.target.value))}
          />
        </label>
        <label>
          Sol Derinlik:
          <input
            type="number"
            value={roomSize.leftDepth}
            onChange={(e) => handleRoomSizeChange('leftDepth', parseFloat(e.target.value))}
          />
        </label>
        <label>
          Sağ Derinlik:
          <input
            type="number"
            value={roomSize.rightDepth}
            onChange={(e) => handleRoomSizeChange('rightDepth', parseFloat(e.target.value))}
          />
        </label>
      </div>

      <button onClick={isEditing ? handleViewRoom : handleEditRoom} style={{ margin: '10px' }}>
        {isEditing ? '3D Görüntüle' : 'Odayı Düzenle'}
      </button>
      <button onClick={handleAddDoor} style={{ margin: '10px' }}>
        Kapı Ekle
      </button>
      <button onClick={handleAddFurniture} style={{ margin: '10px' }}>
        Eşya Ekle
      </button>

      <Canvas camera={{ position: [8, 1.5, 8], fov: 30 }} style={{ height: '100vh' }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <OrbitControls enabled={controlsEnabled && !isEditing} target={[0, 1.25, 0]} />
        <CameraControl isEditing={isEditing} />
        <Room
          size={roomSize}
          items={items}
          setItems={setItems}
          setControlsEnabled={setControlsEnabled}
          addDoorMode={false}
          setAddDoorMode={() => {}}
        />
        {/* GridHelper to show the grid lines on the ground */}
        {isEditing && <gridHelper args={[10, 50, 'black', 'gray']} />}
      </Canvas>
    </div>
  );
};

export default App;
