import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useGLTF, TransformControls } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Demo = ({ id, position, setControlsEnabled, wall, roomSize }) => {
  const modelRef = useRef();
  const controlsRef = useRef();
  const [currentWall, setCurrentWall] = useState(wall);
  const { camera, gl } = useThree();

  // GLTF modelini her bileşen için ayrı ayrı kopyala
  const gltf = useGLTF('/models/1woodline .glb'); // GLTF model path
  const [scene] = useState(() => gltf.scene.clone()); // Her bir model için kopya oluştur

  // Apply different colors to meshes


  // Update the rotation of the model based on the wall it is attached to
  const updateRotation = useCallback((wall) => {
    if (modelRef.current) {
      switch (wall) {
        case 'left':
          modelRef.current.rotation.set(0, Math.PI / 2, 0);
          break;
        case 'right':
          modelRef.current.rotation.set(0, -Math.PI / 2, 0);
          break;
        case 'front':
          modelRef.current.rotation.set(0, 0, 0);
          break;
        case 'back':
          modelRef.current.rotation.set(0, Math.PI, 0);
          break;
        default:
          break;
      }
    }
  }, []);

  // Initial position and rotation setup
  useEffect(() => {
    if (modelRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current);
      const size = new THREE.Vector3();
      box.getSize(size);

      // Eğer pozisyon belirtilmemişse, modeli 'front' duvarına yerleştir
      const initialPosition = position || [0, size.y / 2, -roomSize.depth / 2 + size.z / 2];

      // Modelin başlangıç pozisyonunu ayarla
      modelRef.current.position.set(initialPosition[0], initialPosition[1], initialPosition[2]);

      updateRotation(currentWall);
    }
  }, [position, currentWall, updateRotation, roomSize]);

  // Set up transform controls
  useEffect(() => {
    if (controlsRef.current && modelRef.current) {
      controlsRef.current.attach(modelRef.current);
      controlsRef.current.setSpace('local');
      controlsRef.current.setMode('translate');
    }
  }, [modelRef.current]);

  // Detach controls when component is unmounted
  useEffect(() => {
    return () => {
      if (controlsRef.current) {
        controlsRef.current.detach();
      }
    };
  }, []);

  const handlePointerDown = () => setControlsEnabled(false);
  const handlePointerUp = () => setControlsEnabled(true);

  // Restrict movement to X axis only and prevent dropping below floor level
  useFrame(() => {
    if (controlsRef.current && modelRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current);
      const size = new THREE.Vector3();
      box.getSize(size);

      const { x, z } = modelRef.current.position;

      // Determine which wall the model is closest to
      let newWall = currentWall;

      if (Math.abs(x + roomSize.width / 2) < 0.2) {
        newWall = 'left';
      } else if (Math.abs(x - roomSize.width / 2) < 0.2) {
        newWall = 'right';
      } else if (Math.abs(z + roomSize.depth / 2) < 0.2) {
        newWall = 'front';
      } else if (Math.abs(z - roomSize.depth / 2) < 0.2) {
        newWall = 'back';
      }

      // Update wall and restrict movement accordingly
      if (newWall !== currentWall) {
        setCurrentWall(newWall);
        updateRotation(newWall);
      }

      const yOffset = size.y / 2; // Height offset to prevent the model from dropping below floor level

      // Clamp position to remain within the room boundaries and on the correct wall
      if (newWall === 'left' || newWall === 'right') {
        modelRef.current.position.set(
          newWall === 'left' ? -roomSize.width / 2 + size.x / 2 : roomSize.width / 2 - size.x / 2,
          yOffset,
          THREE.MathUtils.clamp(z, -roomSize.depth / 2 + size.z / 2, roomSize.depth / 2 - size.z / 2)
        );
      } else if (newWall === 'front' || newWall === 'back') {
        modelRef.current.position.set(
          THREE.MathUtils.clamp(x, -roomSize.width / 2 + size.x / 2, roomSize.width / 2 - size.x / 2),
          yOffset,
          newWall === 'front' ? -roomSize.depth / 2 + size.z / 2 : roomSize.depth / 2 - size.z / 2
        );
      }
    }
  });

  return (
    <>
      <TransformControls
        ref={controlsRef}
        args={[camera, gl.domElement]}
        onMouseDown={handlePointerDown}
        onMouseUp={handlePointerUp}
      />
      <primitive ref={modelRef} object={scene} />
    </>
  );
};

export default Demo;
