import React, { useRef } from 'react';
import Door from './Door';
import Demo from './Demo';
import { Line } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

const Room = ({ size, items, setItems, setControlsEnabled, addDoorMode, setAddDoorMode }) => {
  const roomRef = useRef();
  const { raycaster } = useThree();

  // Outer plane boyutları
  const outerSize = {
    width: size.width - 0.1,
    height: size.height - 0.1,
    depth: size.depth - 0.1,
  };

  // Duvara kapı ekleme işlevi
  const handlePlaneClick = (wall) => (event) => {
    if (!addDoorMode) return;

    event.stopPropagation();
    const intersects = raycaster.intersectObject(event.object);
    if (intersects.length > 0) {
      const { point } = intersects[0];
      let position = [0, 1, 0]; // Default kapı pozisyonu

      // Duvara göre doğru pozisyonu ayarlayın
      switch (wall) {
        case 'left':
          position = [-size.width / 2 + 0.05, 0, point.z];
          break;
        case 'right':
          position = [size.width / 2 - 0.05, 0, point.z];
          break;
        case 'front':
          position = [point.x, 0, -size.depth / 2 + 0.05];
          break;
        case 'back':
          position = [point.x, 0, size.depth / 2 - 0.05];
          break;
        default:
          break;
      }

      const newItem = {
        id: items.length + 1,
        type: 'door',
        position,
        wall,
      };

      setItems([...items, newItem]);
      setAddDoorMode(false); // Kapı ekleme modundan çık
    }
  };

  return (
    <group ref={roomRef}>
      {/* İç Duvar Planları */}
      {[
        { wall: 'left', position: [-size.width / 2, size.height / 2, 0], rotation: [0, Math.PI / 2, 0] },
        { wall: 'right', position: [size.width / 2, size.height / 2, 0], rotation: [0, -Math.PI / 2, 0] },
        { wall: 'front', position: [0, size.height / 2, -size.depth / 2], rotation: [0, 0, 0] },
        { wall: 'back', position: [0, size.height / 2, size.depth / 2], rotation: [0, Math.PI, 0] },
        { wall: 'top', position: [0, size.height, 0], rotation: [-Math.PI / 2, 0, 0] },
        { wall: 'bottom', position: [0, 0, 0], rotation: [Math.PI / 2, 0, 0] },
      ].map((plane, index) => (
        <React.Fragment key={index}>
          <mesh
            position={plane.position}
            rotation={plane.rotation}
            onClick={plane.wall !== 'top' && plane.wall !== 'bottom' ? handlePlaneClick(plane.wall) : null}
          >
            <planeGeometry
              args={[
                plane.wall === 'top' || plane.wall === 'bottom' ? size.width : size.depth,
                size.height,
              ]}
            />
            <meshBasicMaterial color={plane.wall === 'top' || plane.wall === 'bottom' ? 'lightblue' : 'blue'} opacity={0.2} transparent />
          </mesh>
          {/* İç Duvar Kenarları */}
          <Line
            points={
              plane.wall === 'left' || plane.wall === 'right'
                ? [
                    [plane.position[0], 0, -size.depth / 2],
                    [plane.position[0], size.height, -size.depth / 2],
                    [plane.position[0], size.height, size.depth / 2],
                    [plane.position[0], 0, size.depth / 2],
                    [plane.position[0], 0, -size.depth / 2],
                  ]
                : plane.wall === 'front' || plane.wall === 'back'
                ? [
                    [-size.width / 2, 0, plane.position[2]],
                    [-size.width / 2, size.height, plane.position[2]],
                    [size.width / 2, size.height, plane.position[2]],
                    [size.width / 2, 0, plane.position[2]],
                    [-size.width / 2, 0, plane.position[2]],
                  ]
                : [
                    [-size.width / 2, plane.position[1], -size.depth / 2],
                    [-size.width / 2, plane.position[1], size.depth / 2],
                    [size.width / 2, plane.position[1], size.depth / 2],
                    [size.width / 2, plane.position[1], -size.depth / 2],
                    [-size.width / 2, plane.position[1], -size.depth / 2],
                  ]
            }
            color="black"
            lineWidth={1}
          />
        </React.Fragment>
      ))}

      {/* Dış Duvar Planları */}
      {[
        { wall: 'left', position: [-outerSize.width / 2, outerSize.height / 2, 0], rotation: [0, Math.PI / 2, 0] },
        { wall: 'right', position: [outerSize.width / 2, outerSize.height / 2, 0], rotation: [0, -Math.PI / 2, 0] },
        { wall: 'front', position: [0, outerSize.height / 2, -outerSize.depth / 2], rotation: [0, 0, 0] },
        { wall: 'back', position: [0, outerSize.height / 2, outerSize.depth / 2], rotation: [0, Math.PI, 0] },
        { wall: 'top', position: [0, outerSize.height, 0], rotation: [-Math.PI / 2, 0, 0] },
        { wall: 'bottom', position: [0, 0, 0], rotation: [Math.PI / 2, 0, 0] },
      ].map((plane, index) => (
        <React.Fragment key={`outer-${index}`}>
          <mesh position={plane.position} rotation={plane.rotation}>
            <planeGeometry
              args={[
                plane.wall === 'top' || plane.wall === 'bottom' ? outerSize.width : outerSize.depth,
                outerSize.height,
              ]}
            />
            <meshBasicMaterial color="green" opacity={0.2} transparent />
          </mesh>
          {/* Dış Duvar Kenarları */}
          <Line
            points={
              plane.wall === 'left' || plane.wall === 'right'
                ? [
                    [plane.position[0], 0, -outerSize.depth / 2],
                    [plane.position[0], outerSize.height, -outerSize.depth / 2],
                    [plane.position[0], outerSize.height, outerSize.depth / 2],
                    [plane.position[0], 0, outerSize.depth / 2],
                    [plane.position[0], 0, -outerSize.depth / 2],
                  ]
                : plane.wall === 'front' || plane.wall === 'back'
                ? [
                    [-outerSize.width / 2, 0, plane.position[2]],
                    [-outerSize.width / 2, outerSize.height, plane.position[2]],
                    [outerSize.width / 2, outerSize.height, plane.position[2]],
                    [outerSize.width / 2, 0, plane.position[2]],
                    [-outerSize.width / 2, 0, plane.position[2]],
                  ]
                : [
                    [-outerSize.width / 2, plane.position[1], -outerSize.depth / 2],
                    [-outerSize.width / 2, plane.position[1], outerSize.depth / 2],
                    [outerSize.width / 2, plane.position[1], outerSize.depth / 2],
                    [outerSize.width / 2, plane.position[1], -outerSize.depth / 2],
                    [-outerSize.width / 2, plane.position[1], -outerSize.depth / 2],
                  ]
            }
            color="red"
            lineWidth={1}
          />
        </React.Fragment>
      ))}

      {/* Kapılar ve Mobilyalar (Demo) */}
      {items.map((item) => {
        if (item.type === 'door') {
          return (
            <Door
              key={item.id}
              id={item.id}
              position={item.position}
              setControlsEnabled={setControlsEnabled}
              wall={item.wall}
              roomSize={size}
              floorBoundaries={{ width: size.width, depth: size.depth }}
            />
          );
        } else if (item.type === 'furniture') {
          return (
            <Demo
              key={item.id}
              id={item.id}
              position={item.position}
              setControlsEnabled={setControlsEnabled}
              wall={item.wall}
              roomSize={size}
            />
          );
        }
        return null;
      })}
    </group>
  );
};

export default Room;
