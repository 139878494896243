import React from 'react';
import './SidebarContent.css';

const SidebarContent = ({ visible, selectedMenu, onClose }) => {
  return (
    <div className={`sidebar-content ${visible ? 'open' : ''}`}>
      <button className="close-button" onClick={onClose}>X</button>
      {selectedMenu === 'bathroomStorageProducts' && (
        <>
          <h2>Banyo Depolama Ürünleri</h2>
          <div className="categories">
            <button>Yüksek dolaplar</button>
            <button>Duvar dolapları</button>
            <button>Servis arabaları</button>
            <button>Tabure ve banklar</button>
          </div>
          <div className="products">
            <h3>Yüksek dolaplar</h3>
            <div className="product-card">
              <p>TÄNNFORSEN</p>
              <p>Kapaklı yüksek dolap, açık gri, 40x35x195 cm</p>
              <p>8.099 ₺</p>
            </div>
            <div className="product-card">
              <p>TÄNNFORSEN</p>
              <p>Kapaklı/çekmeceli yüksek dolap, açık gri, 60x35x195 cm</p>
              <p>13.499 ₺</p>
            </div>
          </div>
        </>
      )}      
      {selectedMenu === 'bathroom' && (
        <>
          <h2>Banyo depolama ürünleri</h2>
          <div className="categories">
            <button>Yüksek dolaplar</button>
            <button>Duvar dolapları</button>
            <button>Servis arabaları</button>
            <button>Tabure ve banklar</button>
          </div>
          <div className="products">
            <h3>Yüksek dolaplar</h3>
            <div className="product-card">
              <p>TÄNNFORSEN</p>
              <p>Kapaklı yüksek dolap, açık gri, 40x35x195 cm</p>
              <p>8.099 ₺</p>
            </div>
            <div className="product-card">
              <p>TÄNNFORSEN</p>
              <p>Kapaklı/çekmeceli yüksek dolap, açık gri, 60x35x195 cm</p>
              <p>13.499 ₺</p>
            </div>
          </div>
        </>
      )}

      {selectedMenu === 'furniture' && (
        <>
          <h2>Mobilya Ürünleri</h2>
          <div className="products">
            <div className="product-card">
              <p>KoltuK XYZ</p>
              <p>Modern tasarım koltuk, 3 kişilik</p>
              <p>5.999 ₺</p>
            </div>
            <div className="product-card">
              <p>Kitaplık ABC</p>
              <p>Ahşap, geniş kitaplık</p>
              <p>2.499 ₺</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SidebarContent;
